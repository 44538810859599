(function() {
  var AdminController, AdminOrderList,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  AdminOrderList = require('dev/admin/admin-order-list');

  AdminController = (function(superClass) {
    extend(AdminController, superClass);

    function AdminController() {
      return AdminController.__super__.constructor.apply(this, arguments);
    }

    AdminController.prototype.getInitialState = function() {
      return {};
    };

    AdminController.prototype.componentDidMount = function() {
      var self;
      self = this;
      return $('#heleensarap-cart').on('click', function(e) {
        if ($(e.target).closest('.overlay-inner').length <= 0) {
          return self.closeOverlay();
        }
      });
    };

    AdminController.prototype.closeOverlay = function() {
      return $('#heleensarap-cart').removeClass('is-overlay');
    };

    AdminController.prototype.render = function() {
      var self;
      self = this;
      return React.DOM.div({
        "className": "overlay-inner"
      }, React.DOM.h1(null, "Beheer La Reine de Turquie"), AdminOrderList(null));
    };

    return AdminController;

  })(Component);

  module.exports = AdminController.toComponent();

}).call(this);
