(function() {
  var OrderConfirmationSuccess,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  OrderConfirmationSuccess = (function(superClass) {
    extend(OrderConfirmationSuccess, superClass);

    function OrderConfirmationSuccess() {
      return OrderConfirmationSuccess.__super__.constructor.apply(this, arguments);
    }

    OrderConfirmationSuccess.prototype.render = function() {
      var self;
      self = this;
      return React.DOM.div(null, React.DOM.p(null, "De betaling is verlopen. Dit komt voor wanneer het betaalproces langer duurde dan 10 minuten."));
    };

    return OrderConfirmationSuccess;

  })(Component);

  module.exports = OrderConfirmationSuccess.toComponent();

}).call(this);
