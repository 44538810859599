(function() {
  var AdminOrder, AdminOrderDetails, AdminOrderList,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  AdminOrder = require('dev/admin/admin-order');

  AdminOrderDetails = require('dev/admin/admin-order-details');

  AdminOrderList = (function(superClass) {
    extend(AdminOrderList, superClass);

    function AdminOrderList() {
      return AdminOrderList.__super__.constructor.apply(this, arguments);
    }

    AdminOrderList.prototype.adminOrderDetails = null;

    AdminOrderList.prototype.getInitialState = function() {
      return {
        orders: {}
      };
    };

    AdminOrderList.prototype.componentDidMount = function() {
      return this.getOrders();
    };

    AdminOrderList.prototype.getOrders = function() {
      var self;
      self = this;
      return $.getJSON('../backend/api/?handler=get/orders').done(function(data) {
        self.state.orders = data;
        return self.forceUpdate();
      });
    };

    AdminOrderList.prototype.showOrderDetails = function(order) {
      this.adminOrderDetails = AdminOrderDetails({
        "data": order,
        "hideOrderDetails": this.hideOrderDetails
      });
      $('.admin-order-list').hide();
      $('.admin-order-details').show();
      return this.forceUpdate();
    };

    AdminOrderList.prototype.hideOrderDetails = function() {
      $('.admin-order-details').hide();
      $('.admin-order-list').show();
      return this.getOrders();
    };

    AdminOrderList.prototype.updateOrder = function() {};

    AdminOrderList.prototype.removeOrder = function() {};

    AdminOrderList.prototype.render = function() {
      var orders, self;
      self = this;
      orders = $.makeArray(this.state.orders).map(function(order, index) {
        return AdminOrder({
          "data": self.state.orders[index],
          "updateOrder": self.updateOrder,
          "removeOrder": self.removeOrder,
          "showOrderDetails": self.showOrderDetails
        }, order['id']);
      });
      return React.DOM.div(null, React.DOM.div({
        "className": "admin-order-list table"
      }, React.DOM.div({
        "className": "table-row"
      }, React.DOM.div({
        "className": "table-cell"
      }, "ID"), React.DOM.div({
        "className": "table-cell"
      }, "Datum"), React.DOM.div({
        "className": "table-cell"
      }, "Naam"), React.DOM.div({
        "className": "table-cell"
      }, "Totaalbedrag"), React.DOM.div({
        "className": "table-cell"
      })), orders), React.DOM.div({
        "className": "admin-order-details"
      }, this.adminOrderDetails));
    };

    return AdminOrderList;

  })(Component);

  module.exports = AdminOrderList.toComponent();

}).call(this);
