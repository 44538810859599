(function() {
  var AdminController, CartItem, Checkout, HeleenSarapCartWidget, OrderConfirmation,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  CartItem = require('dev/cart/cart-item');

  Checkout = require('dev/cart/checkout');

  OrderConfirmation = require('dev/cart/order-confirmation');

  AdminController = require('dev/admin/admin-controller');

  HeleenSarapCartWidget = (function(superClass) {
    var TO_addToCartClick;

    extend(HeleenSarapCartWidget, superClass);

    function HeleenSarapCartWidget() {
      return HeleenSarapCartWidget.__super__.constructor.apply(this, arguments);
    }

    HeleenSarapCartWidget.prototype.overlay = null;

    TO_addToCartClick = null;

    HeleenSarapCartWidget.prototype.getInitialState = function() {
      return {
        cartItems: []
      };
    };

    HeleenSarapCartWidget.prototype.componentDidMount = function() {
      var self;
      self = this;
      this.checkAdmin();
      this.initCart();
      this.initAddToCartButtons();
      return this.getCart().done(function() {
        var orderId;
        if (self.checkLastUrlSegment('dank-voor-uw-bestelling')) {
          orderId = window.location.search.split('=').pop();
          if (!orderId) {
            return;
          }
          return self.getOrder(orderId, orderId != null ? orderId : {
            "true": false
          }).done(function(orderData) {
            self.showConfirmationMessage(orderData);
            if (orderData.payment.status === 'payed') {
              self.sendOrderConfirmation(orderData);
            }
            return self.bindEmailActions();
          });
        }
      });
    };

    HeleenSarapCartWidget.prototype.roundAmount = function(num) {
      return Math.round(num * 100) / 100;
    };

    HeleenSarapCartWidget.prototype.checkAdmin = function() {
      if (!this.checkLastUrlSegment('admin')) {
        return;
      }
      $('#heleensarap-cart').addClass('is-overlay');
      this.overlay = AdminController(null);
      return this.forceUpdate();
    };

    HeleenSarapCartWidget.prototype.bindEmailActions = function() {
      $('.mail-actions li').on('click', function(e) {
        if (e != null) {
          e.preventDefault();
        }
        return $(e.target).closest('li').addClass('active').find('input')[0].focus();
      });
      return $('.mail-actions .js-do-action').on('click', function(e) {
        if (e != null) {
          e.preventDefault();
        }
        return $(e.target).closest('li').addClass('active').find('input[type="tel"], input[type="email"]').val('');
      });
    };

    HeleenSarapCartWidget.prototype.setOrderData = function(orderData) {
      return this.state = orderData;
    };

    HeleenSarapCartWidget.prototype.getOrder = function(orderId, doGetPayment) {
      var data, self;
      self = this;
      data = {};
      if (doGetPayment) {
        data.doGetPayment = true;
      }
      return $.ajax({
        url: '../backend/api/?handler=get/order/' + orderId,
        dataType: 'json',
        method: 'get',
        data: data
      }).done((function(_this) {
        return function(data) {
          self.setOrderData(data);
          return self.forceUpdate();
        };
      })(this)).fail((function(_this) {
        return function(xhr, status, err) {
          return console.log(status, err);
        };
      })(this));
    };

    HeleenSarapCartWidget.prototype.initAddToCartButtons = function() {
      var i, options, self;
      self = this;
      options = '';
      i = 1;
      while (i <= 18) {
        options += '<option>' + i + '</option>';
        i++;
      }
      return $('.add-to-cart').text('Zet in mijn wijndoos').append('<select>' + options + '</select>').click(function(e) {
        if ($(e.target).closest('select').length <= 0) {
          return self.addToCartClick(e, $(e.target).find('select').val());
        }
      });
    };

    HeleenSarapCartWidget.prototype.addToCartClick = function(e, amount) {
      var index, isNew, title;
      e.preventDefault();
      if (!this.state.cartItems) {
        this.state.cartItems = [];
      }
      amount = parseInt(amount);
      title = $(e.target).data('title');
      isNew = true;
      for (index in this.state.cartItems) {
        if (this.state.cartItems[index].title === title) {
          this.state.cartItems[index].amount = parseInt(this.state.cartItems[index].amount) + amount;
          isNew = false;
        }
      }
      if (isNew) {
        this.state.cartItems.push({
          title: title,
          image: $(e.target).closest('.panel').find('img').attr('src'),
          price: parseFloat($(e.target).data('price')),
          amount: parseInt(amount)
        });
      }
      $('.checkout').addClass('item-added').addClass('checkout--active');
      $(e.target).addClass('item-added');
      clearTimeout(this.TO_addToCartClick);
      this.TO_addToCartClick = setTimeout((function() {
        $('.checkout').removeClass('item-added');
        $('.checkout').fadeOut(function() {
          $('.checkout').removeClass('checkout--active');
          return setTimeout((function() {
            return $('.checkout').show();
          }), 250);
        });
        return $(e.target).removeClass('item-added');
      }), 2500);
      this.setState(this.state);
      this.setCart(this.state);
      return this.forceUpdate();
    };

    HeleenSarapCartWidget.prototype.initCart = function() {
      var self;
      self = this;
      return $('.checkout').each(function() {
        $('.checkout__button').on({
          'click': function(e) {
            e.preventDefault();
            clearTimeout(this.TO_addToCartClick);
            return $('.checkout').addClass('checkout--active');
          }
        });
        $('.checkout__cancel').on({
          'click': function(e) {
            e.preventDefault();
            return $('.checkout').removeClass('checkout--active');
          }
        });
        $('.checkout').on({
          'click': function(e) {
            e.preventDefault();
            if ($(e.target).closest('.checkout-grid__item').length === 0) {
              return $('.checkout').removeClass('checkout--active');
            }
          }
        });
        $('.checkout__option--loud').on({
          'click': function(e) {
            self.overlay = Checkout({
              "cartItems": self.cartItems,
              "totalPrice": self.totalPrice
            });
            $('#heleensarap-cart').addClass('in-payment-process');
            setTimeout((function() {
              return $('#heleensarap-cart').one('click', function(e) {
                if ($(e.target).closest('.payment-process-wrapper').length === 0) {
                  return $('#heleensarap-cart').removeClass('in-payment-process');
                }
              });
            }));
            return self.forceUpdate();
          }
        });
        return $('#heleensarap-cart').removeClass('in-payment-process');
      });
    };

    HeleenSarapCartWidget.prototype.updateItem = function(title, amount) {
      var ref, ref1;
      if ((ref = this.state.cartItems.filter(function(item) {
        return item.title === title;
      })) != null) {
        if ((ref1 = ref[0]) != null) {
          ref1.amount = parseInt(amount);
        }
      }
      this.setCart(this.state);
      return this.forceUpdate();
    };

    HeleenSarapCartWidget.prototype.removeItem = function(title) {
      var index;
      for (index in this.state.cartItems) {
        if (this.state.cartItems[index].title === title) {
          this.state.cartItems.splice(index, 1);
        }
      }
      this.setCart(this.state);
      this.setState(this.state);
      return this.forceUpdate();
    };

    HeleenSarapCartWidget.prototype.getPayment = function() {
      var self;
      self = this;
      return $.ajax({
        url: '../backend/api/?handler=get/payment',
        dataType: 'json',
        method: 'get'
      }).done((function(_this) {
        return function(data) {
          self.state.cartItems = data;
          return self.forceUpdate();
        };
      })(this)).fail((function(_this) {
        return function(xhr, status, err) {
          return console.log(status, err);
        };
      })(this));
    };

    HeleenSarapCartWidget.prototype.getCart = function() {
      var self;
      self = this;
      return $.ajax({
        url: '../backend/api/?handler=get/cart',
        dataType: 'json',
        method: 'get'
      }).done((function(_this) {
        return function(data) {
          self.state.cartItems = data;
          return self.forceUpdate();
        };
      })(this)).fail((function(_this) {
        return function(xhr, status, err) {
          return console.log(status, err);
        };
      })(this));
    };

    HeleenSarapCartWidget.prototype.setCart = function(data) {
      var self;
      self = this;
      return $.ajax({
        url: '../backend/api/?handler=post/cart',
        dataType: 'json',
        method: 'post',
        data: data
      }).done((function(_this) {
        return function(data) {
          return self.forceUpdate();
        };
      })(this)).fail((function(_this) {
        return function(xhr, status, err) {
          return console.log(status, err);
        };
      })(this));
    };

    HeleenSarapCartWidget.prototype.checkLastUrlSegment = function(lastSegment) {
      return window.location.pathname.split('/').pop() === lastSegment;
    };

    HeleenSarapCartWidget.prototype.showConfirmationMessage = function(data) {
      $('#heleensarap-cart').addClass('is-overlay');
      this.overlay = OrderConfirmation({
        "data": data
      });
      this.forceUpdate();
      return true;
    };

    HeleenSarapCartWidget.prototype.sendOrderConfirmation = function(data) {
      var self;
      self = this;
      return $.ajax({
        url: '../backend/api/?handler=post/order_confirmation',
        dataType: 'json',
        method: 'post',
        data: data
      }).done((function(_this) {
        return function(data) {
          self.state.cartItems = {};
          self.setCart(self.state);
          return self.forceUpdate();
        };
      })(this)).fail((function(_this) {
        return function(xhr, status, err) {
          return console.log(status, err);
        };
      })(this));
    };

    HeleenSarapCartWidget.prototype.render = function() {
      var cItems, index, item, self;
      self = this;
      this.cartItems = [];
      this.totalPrice = 0;
      for (index in this.state.cartItems) {
        item = self.state.cartItems[index];
        this.cartItems.push(item);
        this.totalPrice += item.amount * item.price;
      }
      if (!this.totalPrice) {
        this.totalPrice = 0;
      } else {
        this.totalPrice = this.totalPrice.toFixed(2);
      }
      cItems = $.makeArray(this.cartItems).map(function(item, index) {
        return CartItem({
          "item": self.cartItems[index],
          "updateItem": self.updateItem,
          "removeItem": self.removeItem
        }, item['title']);
      });
      return React.DOM.div(null, React.DOM.div({
        "className": "checkout"
      }, React.DOM.div({
        "className": "checkout-grid"
      }, React.DOM.div({
        "className": "checkout-grid__item checkout-grid__item--summary"
      }, React.DOM.button({
        "className": "checkout__close checkout__cancel"
      }, React.DOM.i({
        "className": "icon fa fa-close"
      })), React.DOM.div({
        "className": "checkout__total"
      }, "\u20ac", this.totalPrice), React.DOM.button({
        "className": "checkout__option checkout__option--loud"
      }, "Bestel nu"), React.DOM.button({
        "className": "checkout__option checkout__option--silent checkout__cancel"
      }, "of winkel verder ", React.DOM.i({
        "className": "fa fa-angle-right"
      })), React.DOM.a({
        "className": "checkout__button",
        "href": "#"
      }, React.DOM.span({
        "className": "checkout__text"
      }, React.DOM.svg({
        "className": "checkout__icon",
        "width": "30px",
        "height": "30px",
        "viewBox": "0 0 35 35"
      }, React.DOM.path({
        "d": "M33.623,8.004c-0.185-0.268-0.486-0.434-0.812-0.447L12.573,6.685c-0.581-0.025-1.066,0.423-1.091,1.001 c-0.025,0.578,0.423,1.065,1.001,1.091L31.35,9.589l-3.709,11.575H11.131L8.149,4.924c-0.065-0.355-0.31-0.652-0.646-0.785 L2.618,2.22C2.079,2.01,1.472,2.274,1.26,2.812s0.053,1.146 0.591,1.357l4.343,1.706L9.23,22.4c0.092,0.497,0.524,0.857,1.03,0.857 h0.504l-1.15,3.193c-0.096,0.268-0.057,0.565,0.108,0.798c0.163,0.232,0.429,0.37,0.713,0.37h0.807 c-0.5,0.556-0.807,1.288-0.807,2.093c0,1.732,1.409,3.141,3.14,3.141c1.732,0,3.141-1.408,3.141-3.141c0-0.805-0.307-1.537-0.807-2.093h6.847c-0.5,0.556-0.806,1.288-0.806,2.093c0,1.732,1.407,3.141,3.14,3.141 c1.731,0,3.14-1.408,3.14-3.141c0-0.805-0.307-1.537-0.806-2.093h0.98c0.482,0,0.872-0.391,0.872-0.872s-0.39-0.873-0.872-0.873 H11.675l0.942-2.617h15.786c0.455,0,0.857-0.294,0.996-0.727l4.362-13.608C33.862,8.612,33.811,8.272,33.623,8.004z M13.574,31.108c-0.769,0-1.395-0.626-1.395-1.396s0.626-1.396,1.395-1.396c0.77,0,1.396,0.626,1.396,1.396S14.344,31.108,13.574,31.108z M25.089,31.108c-0.771,0-1.396 0.626-1.396-1.396s0.626-1.396,1.396-1.396c0.77,0,1.396,0.626,1.396,1.396 S25.858,31.108,25.089,31.108z"
      }))))), cItems)), this.overlay);
    };

    return HeleenSarapCartWidget;

  })(Component);

  module.exports = HeleenSarapCartWidget.toComponent();

}).call(this);
