(function() {
  var OrderConfirmation, OrderConfirmationCancelled, OrderConfirmationExpired, OrderConfirmationSuccess,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  OrderConfirmationCancelled = require('dev/cart/order-confirmation-cancelled');

  OrderConfirmationExpired = require('dev/cart/order-confirmation-expired');

  OrderConfirmationSuccess = require('dev/cart/order-confirmation-success');

  OrderConfirmation = (function(superClass) {
    extend(OrderConfirmation, superClass);

    function OrderConfirmation() {
      return OrderConfirmation.__super__.constructor.apply(this, arguments);
    }

    OrderConfirmation.prototype.getInitialState = function() {
      return {};
    };

    OrderConfirmation.prototype.componentDidMount = function() {
      var self;
      self = this;
      return $('#heleensarap-cart').on('click', function(e) {
        if ($(e.target).closest('.confirmation-wrapper').length === 0) {
          return $('#heleensarap-cart').removeClass('is-overlay');
        }
      });
    };

    OrderConfirmation.prototype.onSubmit = function(e) {
      if (e != null) {
        e.preventDefault();
      }
      return $(e.target).closest('form').find('input[type="submit"]').trigger('click');
    };

    OrderConfirmation.prototype.render = function() {
      var component, ref, self;
      self = this;
      if ((ref = this.props.data.payment.status) === 'open' || ref === 'paid' || ref === 'paidout') {
        component = OrderConfirmationSuccess(null);
      } else if (this.props.data.payment.status === 'cancelled') {
        component = OrderConfirmationCancelled(null);
      } else if (this.props.data.payment.status === 'expired') {
        component = OrderConfirmationExpired(null);
      }
      return React.DOM.div({
        "className": "overlay-inner confirmation-wrapper"
      }, component, React.DOM.a({
        "className": "button order-button button-l checkout__option checkout__option--loud",
        "href": "./"
      }, "Terug naar de webwinkel"));
    };

    return OrderConfirmation;

  })(Component);

  module.exports = OrderConfirmation.toComponent();

}).call(this);
