(function() {
  var HeleenSarapCartItem,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  HeleenSarapCartItem = (function(superClass) {
    extend(HeleenSarapCartItem, superClass);

    function HeleenSarapCartItem() {
      return HeleenSarapCartItem.__super__.constructor.apply(this, arguments);
    }

    HeleenSarapCartItem.prototype.componentDidMount = function() {
      var self;
      self = this;
      return $('.checkout-grid__item-remove').on({
        'click': function(e) {
          return self.removeItem($(e.target).closest('div').data('title'));
        }
      });
    };

    HeleenSarapCartItem.prototype.removeItem = function(item) {
      return this.props.removeItem(item);
    };

    HeleenSarapCartItem.prototype.updateItem = function(item, amount) {
      return this.props.updateItem(item, amount);
    };

    HeleenSarapCartItem.prototype.handleChange = function(e) {
      var field;
      field = $(e.target).attr('name');
      this.props.item.amount = parseInt($(e.target).closest('select')[0].value);
      return this.updateItem($(e.target).closest('div').data('title'), this.props.item.amount);
    };

    HeleenSarapCartItem.prototype.render = function() {
      var price, self;
      self = this;
      price = Number(this.props.item.price).toFixed(2);
      return React.DOM.div({
        "className": "checkout-grid__item",
        "data-title": this.props.item.title
      }, React.DOM.img({
        "src": this.props.item.image
      }), React.DOM.h4({
        "title": this.props.item.title
      }, React.DOM.select({
        "name": "amount",
        "value": this.props.item.amount,
        "onChange": this.handleChange
      }, React.DOM.option({
        "value": "1"
      }, "1x"), React.DOM.option({
        "value": "2"
      }, "2x"), React.DOM.option({
        "value": "3"
      }, "3x"), React.DOM.option({
        "value": "4"
      }, "4x"), React.DOM.option({
        "value": "5"
      }, "5x"), React.DOM.option({
        "value": "6"
      }, "6x"), React.DOM.option({
        "value": "7"
      }, "7x"), React.DOM.option({
        "value": "8"
      }, "8x"), React.DOM.option({
        "value": "9"
      }, "9x"), React.DOM.option({
        "value": "10"
      }, "10x"), React.DOM.option({
        "value": "11"
      }, "11x"), React.DOM.option({
        "value": "12"
      }, "12x"), React.DOM.option({
        "value": "13"
      }, "13x"), React.DOM.option({
        "value": "14"
      }, "14x"), React.DOM.option({
        "value": "15"
      }, "15x"), React.DOM.option({
        "value": "16"
      }, "16x"), React.DOM.option({
        "value": "17"
      }, "17x"), React.DOM.option({
        "value": "18"
      }, "18x")), "\u00a0", this.props.item.title), React.DOM.span({
        "className": "price"
      }, "\u20ac", price, "\x2Fst"), React.DOM.button({
        "className": "checkout-grid__item-remove"
      }, React.DOM.i({
        "className": "icon fa fa-close"
      })));
    };

    return HeleenSarapCartItem;

  })(Component);

  module.exports = HeleenSarapCartItem.toComponent();

}).call(this);
