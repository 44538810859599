(function() {
  var GridHeart, PageOverlay, WiwywSite,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  GridHeart = require('dev/grid-heart/grid-heart');

  PageOverlay = require('dev/page-overlay/page-overlay');

  WiwywSite = (function(superClass) {
    var onMobile;

    extend(WiwywSite, superClass);

    function WiwywSite() {
      return WiwywSite.__super__.constructor.apply(this, arguments);
    }

    onMobile = false;

    WiwywSite.prototype.getInitialState = function() {
      return {
        page: null,
        coords: null
      };
    };

    WiwywSite.prototype.componentDidMount = function() {
      return this.bindEvents();
    };

    WiwywSite.prototype.bindEvents = function() {
      var self;
      self = this;
      return $('a[data-page]').on({
        'click': function(e) {
          e.preventDefault();
          return self.goToPage($(e.target).data('page'), [$(e.target).closest('.grid-row').data('row'), $(e.target).data('block')]);
        }
      });
    };

    WiwywSite.prototype.render = function() {
      var self;
      self = this;
      return React.DOM.div(null, React.DOM.h1({
        "className": "liwyw"
      }, "Love ", React.DOM.span({
        "className": "white"
      }, "Is What You"), " Want"), GridHeart({
        "onMobile": this.onMobile
      }), React.DOM.footer({
        "className": "footer"
      }, React.DOM.a({
        "className": "logo-muscom",
        "href": "http://muscom.nl",
        "target": "_blank"
      }, "Museum voor Communicatie"), React.DOM.nav({
        "className": "nav nav-primary"
      }, React.DOM.a({
        "className": "btn",
        "href": "#",
        "data-page": "jouw-verhaal"
      }, "Stuur je verhaal in"), React.DOM.a({
        "className": "btn",
        "href": "#",
        "data-page": "ons-verhaal"
      }, "Lees ons verhaal")), React.DOM.nav({
        "className": "nav nav-secondary"
      }, React.DOM.a({
        "href": "#",
        "data-page": "pers"
      }, "Pers"), React.DOM.a({
        "href": "#",
        "data-page": "contact"
      }, "Contact"), React.DOM.div({
        "className": "share-icons cf"
      }, React.DOM.a({
        "title": "Like <3",
        "href": "https://www.facebook.com/MusComNL/",
        "target": "_blank",
        "className": "facebook pulse"
      }, React.DOM.img({
        "className": "svg",
        "src": "img/icon/icon_social_fb.svg"
      })), React.DOM.a({
        "href": "http://twitter.com/muscom_nl",
        "target": "_blank",
        "className": "twitter pulse"
      }, React.DOM.img({
        "className": "svg",
        "src": "img/icon/icon_social_twitter.svg"
      })), React.DOM.a({
        "href": "https://instagram.com/Muscomnl/",
        "target": "_blank",
        "className": "instagram pulse"
      }, React.DOM.img({
        "className": "svg",
        "src": "img/icon/icon_social_insta-01.svg"
      }))))), PageOverlay({
        "page": this.state.page,
        "coords": this.state.coords,
        "goToPage": this.goToPage
      }));
    };

    return WiwywSite;

  })(Component);

  module.exports = WiwywSite.toComponent();

}).call(this);
