(function() {
  var OrderConfirmationSuccess,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  OrderConfirmationSuccess = (function(superClass) {
    extend(OrderConfirmationSuccess, superClass);

    function OrderConfirmationSuccess() {
      return OrderConfirmationSuccess.__super__.constructor.apply(this, arguments);
    }

    OrderConfirmationSuccess.prototype.render = function() {
      var self;
      self = this;
      return React.DOM.div(null, React.DOM.p(null, "De bestelling is nu gedaan. Ik verwacht mijn wijnen binnen 5 werkdagen. Op de website van La Reine de Turquie wil ik nog een paar dingen doen."), React.DOM.ul({
        "className": "mail-actions"
      }, React.DOM.li({
        "data-action": "send-info-about-wines",
        "className": "js-do-action"
      }, "Meer informatie over mijn wijnen naar mijn mailadres sturen"), React.DOM.li({
        "data-action": "send-info-about-turkey",
        "className": "js-do-action"
      }, "Meer te weten komen over de achtergronden van Turkije als wijnland"), React.DOM.li({
        "data-action": "tell-a-friend"
      }, "Deze bekende van mij moet ook van La Reine de Turquie weten!", React.DOM.form({
        "onSubmit": this.onSubmit
      }, React.DOM.input({
        "required": true,
        "placeholder": "Bijv: mail@example.com",
        "name": "email",
        "type": "email"
      }), React.DOM.input({
        "type": "submit",
        "value": "Verzend een mail",
        "className": "js-do-action"
      }))), React.DOM.li({
        "data-action": "call-me"
      }, "Ik wil dat La Reine de Turquie mij even belt, mijn telefoonnummer is:", React.DOM.form({
        "onSubmit": this.onSubmit
      }, React.DOM.input({
        "required": true,
        "placeholder": "Bijv: 06-12345678",
        "name": "phone",
        "type": "tel"
      }), React.DOM.input({
        "type": "submit",
        "value": "Bel mij terug",
        "className": "js-do-action"
      })))));
    };

    return OrderConfirmationSuccess;

  })(Component);

  module.exports = OrderConfirmationSuccess.toComponent();

}).call(this);
