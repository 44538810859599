(function() {
  var AdminOrderDetails, AdminOrderProduct,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  AdminOrderProduct = require('dev/admin/admin-order-product');

  AdminOrderDetails = (function(superClass) {
    extend(AdminOrderDetails, superClass);

    function AdminOrderDetails() {
      return AdminOrderDetails.__super__.constructor.apply(this, arguments);
    }

    AdminOrderDetails.prototype.getInitialState = function() {
      return {};
    };

    AdminOrderDetails.prototype.componentDidMount = function() {};

    AdminOrderDetails.prototype.hideOrderDetails = function(e) {
      if (e != null) {
        e.preventDefault();
      }
      return this.props.hideOrderDetails();
    };

    AdminOrderDetails.prototype.sendOrder = function(e) {
      var self;
      self = this;
      e.preventDefault();
      if (confirm('Is de bestelling naar de klant verzonden?')) {
        return $.post('../backend/api/?handler=put/order_status/' + this.props.data.id, this.props.data, (function(data) {
          self.props.data.date_sent = data;
          return self.forceUpdate();
        }), 'json');
      }
    };

    AdminOrderDetails.prototype.render = function() {
      var orderSent, products, ref, ref1, ref2, ref3, ref4, ref5, self;
      self = this;
      if ((ref = this.props.data) != null ? ref.dt_sent : void 0) {
        orderSent = 'Bestelling verzonden op ' + ((ref1 = this.props.data) != null ? ref1.date_sent : void 0);
      } else {
        orderSent = '<a class="button">Ik heb de bestelling verzonden</a>';
      }
      products = $.makeArray(this.props.data.products).map(function(product, index) {
        return AdminOrderProduct({
          "data": product
        });
      });
      return React.DOM.div(null, React.DOM.p(null, React.DOM.a({
        "onClick": this.hideOrderDetails,
        "className": "button"
      }, "Terug naar het overzicht"), React.DOM.br(null)), React.DOM.hr(null), React.DOM.p(null, this.props.data.first_name, "\u00a0", this.props.data.last_name, React.DOM.br(null), this.props.data.street, React.DOM.br(null), ((ref2 = this.props) != null ? ref2.data.zip : void 0), "\u00a0", ((ref3 = this.props) != null ? ref3.data.place : void 0)), React.DOM.p(null, ((ref4 = this.props) != null ? ref4.data.email : void 0), React.DOM.br(null), ((ref5 = this.props) != null ? ref5.data.phone : void 0)), React.DOM.hr(null), React.DOM.div({
        "className": "table"
      }, products, React.DOM.div({
        "className": "table-row"
      }, React.DOM.div({
        "className": "table-cell"
      }), React.DOM.div({
        "className": "table-cell"
      }), React.DOM.div({
        "className": "table-cell"
      }, "-----------")), React.DOM.div({
        "className": "table-row"
      }, React.DOM.div({
        "className": "table-cell"
      }), React.DOM.div({
        "className": "table-cell"
      }), React.DOM.div({
        "className": "table-cell"
      }, "\u20ac ", React.DOM.b(null, this.props.data.total_amount)))), React.DOM.hr(null), React.DOM.p(null, "Bestelling geplaatst op ", this.props.data.date, React.DOM.div({
        "onClick": this.sendOrder,
        "dangerouslySetInnerHTML": {
          __html: orderSent
        }
      })));
    };

    return AdminOrderDetails;

  })(Component);

  module.exports = AdminOrderDetails.toComponent();

}).call(this);
