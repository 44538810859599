(function() {
  var AdminOrder,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  AdminOrder = (function(superClass) {
    extend(AdminOrder, superClass);

    function AdminOrder() {
      return AdminOrder.__super__.constructor.apply(this, arguments);
    }

    AdminOrder.prototype.getInitialState = function() {
      return {};
    };

    AdminOrder.prototype.componentDidMount = function() {};

    AdminOrder.prototype.showOrderDetails = function() {
      return this.props.showOrderDetails(this.props.data);
    };

    AdminOrder.prototype.render = function() {
      var ref, ref1, ref2, ref3, ref4, ref5;
      return React.DOM.div({
        "className": "table-row",
        "onClick": this.showOrderDetails
      }, React.DOM.div({
        "className": "table-cell"
      }, ((ref = this.props.data) != null ? ref.id : void 0)), React.DOM.div({
        "className": "table-cell"
      }, ((ref1 = this.props.data) != null ? ref1.date : void 0)), React.DOM.div({
        "className": "table-cell"
      }, ((ref2 = this.props.data) != null ? ref2.first_name : void 0), "\u00a0", ((ref3 = this.props.data) != null ? ref3.last_name : void 0)), React.DOM.div({
        "className": "table-cell"
      }, ((ref4 = this.props.data) != null ? ref4.total_amount : void 0)), React.DOM.div({
        "className": "table-cell"
      }, (((ref5 = this.props.data) != null ? ref5.dt_sent : void 0) ? 'Verzonden' : void 0)));
    };

    return AdminOrder;

  })(Component);

  module.exports = AdminOrder.toComponent();

}).call(this);
