(function() {
  var AdminOrderProduct,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  AdminOrderProduct = (function(superClass) {
    extend(AdminOrderProduct, superClass);

    function AdminOrderProduct() {
      return AdminOrderProduct.__super__.constructor.apply(this, arguments);
    }

    AdminOrderProduct.prototype.getInitialState = function() {
      return {};
    };

    AdminOrderProduct.prototype.componentDidMount = function() {};

    AdminOrderProduct.prototype.render = function() {
      return React.DOM.div({
        "className": "table-row"
      }, React.DOM.div({
        "className": "table-cell"
      }, this.props.data.title), React.DOM.div({
        "className": "table-cell"
      }, this.props.data.amount, "x \u20ac ", this.props.data.product_price), React.DOM.div({
        "className": "table-cell"
      }, "\u20ac ", React.DOM.b(null, this.props.data.total_price)));
    };

    return AdminOrderProduct;

  })(Component);

  module.exports = AdminOrderProduct.toComponent();

}).call(this);
