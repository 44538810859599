(function() {
  var Checkout, CheckoutItem,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  CheckoutItem = require('dev/cart/checkout-item');

  Checkout = (function(superClass) {
    var cItems;

    extend(Checkout, superClass);

    function Checkout() {
      return Checkout.__super__.constructor.apply(this, arguments);
    }

    cItems = null;

    Checkout.prototype.getInitialState = function() {
      return {};
    };

    Checkout.prototype.componentDidMount = function() {
      var self;
      self = this;
      this.numberOfBottles = 0;
      this.cItems = $.makeArray(this.props.cartItems).map(function(item, index) {
        self.numberOfBottles = self.props.cartItems[index].amount;
        return CheckoutItem({
          "item": self.props.cartItems[index]
        }, item['title']);
      });
      this.shippingCostsData = {
        amount: 1,
        title: 'Verzendkosten',
        price: this.calculateShippingCosts()
      };
      this.shippingCostsRow = CheckoutItem({
        "item": this.shippingCostsData
      });
      return this.forceUpdate();
    };

    Checkout.prototype.handleChange = function(e) {
      var field;
      field = $(e.target).attr('name');
      this.state[field] = $(e.target)[0].value;
      return this.setState(this.state);
    };

    Checkout.prototype.onSubmit = function(e) {
      var data, self;
      if (e != null) {
        e.preventDefault();
      }
      self = this;
      data = this.state;
      data['cartItems'] = this.props.cartItems;
      return this.handleSubmit(data).done(function(paymentUrl) {
        return document.location = paymentUrl;
      }).fail((function(_this) {
        return function(xhr, status, err) {
          return console.log(status, err);
        };
      })(this));
    };

    Checkout.prototype.handleSubmit = function(data) {
      var self;
      self = this;
      data.shipping_costs = this.shippingCostsData.price;
      return $.ajax({
        url: '../backend/api/?handler=post/order',
        dataType: 'json',
        method: 'post',
        data: data
      });
    };

    Checkout.prototype.calculateShippingCosts = function() {
      var numberOfBoxes;
      if (true) {
        numberOfBoxes = Math.ceil(this.numberOfBottles / 6);
      }
      return 6.95 * numberOfBoxes;
    };

    Checkout.prototype.render = function() {
      var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, self;
      self = this;
      return React.DOM.form({
        "onSubmit": this.onSubmit,
        "className": "payment-process-wrapper",
        "method": "post"
      }, React.DOM.p(null, "Deze producten wil ik graag bestellen."), React.DOM.div({
        "className": "table"
      }, this.cItems, this.shippingCostsRow), React.DOM.p(null, "Hier zijn mijn gegevens. Ik wil mijn wijnen binnen vijf werkdagen ontvangen."), React.DOM.hr(null), React.DOM.div({
        "className": "form-block cf input-text"
      }, React.DOM.label(null, "Voornaam *"), React.DOM.input({
        "required": true,
        "type": "text",
        "name": "first_name",
        "value": ((ref = this.state) != null ? ref.first_name : void 0),
        "onChange": this.handleChange
      })), React.DOM.div({
        "className": "form-block cf input-text"
      }, React.DOM.label(null, "Achternaam *"), React.DOM.input({
        "required": true,
        "type": "text",
        "name": "last_name",
        "value": ((ref1 = this.state) != null ? ref1.last_name : void 0),
        "onChange": this.handleChange
      })), React.DOM.div({
        "className": "form-block cf input-text"
      }, React.DOM.label(null, "Straatnaam en huisnummer *"), React.DOM.input({
        "required": true,
        "type": "text",
        "name": "street",
        "value": ((ref2 = this.state) != null ? ref2.street : void 0),
        "onChange": this.handleChange,
        "value": ((ref3 = this.state) != null ? ref3.name : void 0),
        "onChange": this.handleChange
      })), React.DOM.div({
        "className": "form-block cf input-text"
      }, React.DOM.label(null, "Postcode *"), React.DOM.input({
        "required": true,
        "type": "text",
        "name": "zip",
        "value": ((ref4 = this.state) != null ? ref4.zip : void 0),
        "onChange": this.handleChange
      })), React.DOM.div({
        "className": "form-block cf input-text"
      }, React.DOM.label(null, "Plaats *"), React.DOM.input({
        "required": true,
        "type": "text",
        "name": "place",
        "value": ((ref5 = this.state) != null ? ref5.place : void 0),
        "onChange": this.handleChange
      })), React.DOM.div({
        "className": "form-block cf input-text"
      }, React.DOM.label(null, "E-mailadres *"), React.DOM.input({
        "required": true,
        "type": "email",
        "name": "email",
        "value": ((ref6 = this.state) != null ? ref6.email : void 0),
        "onChange": this.handleChange
      })), React.DOM.div({
        "className": "form-block cf input-text"
      }, React.DOM.label(null, "Telefoonnummer *"), React.DOM.input({
        "required": true,
        "type": "tel",
        "name": "phone",
        "value": ((ref7 = this.state) != null ? ref7.phone : void 0),
        "onChange": this.handleChange
      })), React.DOM.div({
        "className": "payment-wrapper"
      }, React.DOM.input({
        "type": "submit",
        "value": "Verder naar betalen",
        "className": "button order-button button-l checkout__option checkout__option--loud"
      })));
    };

    return Checkout;

  })(Component);

  module.exports = Checkout.toComponent();

}).call(this);
