(function() {
  var CheckoutItem,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  CheckoutItem = (function(superClass) {
    extend(CheckoutItem, superClass);

    function CheckoutItem() {
      return CheckoutItem.__super__.constructor.apply(this, arguments);
    }

    CheckoutItem.prototype.getInitialState = function() {
      return {};
    };

    CheckoutItem.prototype.componentDidMount = function() {};

    CheckoutItem.prototype.render = function() {
      var price, self;
      self = this;
      price = this.props.item.amount * this.props.item.price;
      return React.DOM.div({
        "className": "checkout-item"
      }, React.DOM.span({
        "className": "amount"
      }, this.props.item.amount, "x"), React.DOM.span({
        "className": "title"
      }, this.props.item.title), React.DOM.span({
        "className": "price"
      }, "\u20ac", price.toFixed(2)));
    };

    return CheckoutItem;

  })(Component);

  module.exports = CheckoutItem.toComponent();

}).call(this);
